@charset "utf-8"

img,
figure
  max-width: 100%
  &.alignright
    float: right
    margin: 0 0 1em 1em
  &.alignleft
    float: left
    margin: 0 1em 1em 0
  &.aligncenter
    display: block
    margin-left: auto
    margin-right: auto
    margin-bottom: 1em

.wp-caption-text
  margin-bottom: 1em