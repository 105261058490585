.page-context-header
  @extend %viewport-margins-flex

  @supports (position: sticky) or (position: -webkit-sticky)
    width: calc(100% - (#{$viewport_margin_calc} * 2))
    > *
      position: absolute

.page-context-header,
.site-main .page-context-nav
  @extend %sticky-top

  @supports (position: sticky) or (position: -webkit-sticky)
    top: 100px
    z-index: 1
    margin-top: -2.8333em //* -1
      // margin-bottom: 2.8333em * -0.5
    margin-bottom: 0

    @include tablet
      top: 115px

.site-masthead .context-header
  transition: all 1s 0.3s
  // opacity: 0.62
  position: relative
  top: 15px

  // &:hover
    // opacity: 1
    // transition: opacity 0.3s

.page-context-nav
  // box-shadow: 0 0 24px white
  font-size: 0.8125rem
  // border-radius: 4px
  // background-color: #f8f7fd
  background-color: white
  // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1)
  padding: 1em 0
  position: relative
  justify-items: flex-start
  &::before, &::after
    background-color: $primary
    // border: 0 solid fade-out($primary, 0.5)
    // border-width: thin 0
    height: 1px
    max-width: 280px
    width: 100%
    content: ""
    position: absolute
    left: 0
  &::before
    // height: 3px
    top: -6px
  &::after
    bottom: 0

  // fade-out($primary, 0.96)
  // color: white
  // background-color: $primary
  // padding: 0.333em 0.666em

  &:focus
    outline: 0 none
    box-shadow: 0 none

  &, &:not([open])
    display: flex

  .page-context-nav--toggle
    display: none

  button
    background-color: transparent
    border-radius: 0
    border: 0 none
    color: inherit
    text-align: left

  dialog
    border: 0 none

  .page-context-nav--list-container
    flex: 0 1
    display: contents
    color: inherit

  &, & ul
    flex-wrap: wrap
    justify-content: center
    // width: fit-content

  .page-context-nav--header
    font-weight: 600
    flex: 0 1 auto
    display: inline-block

  .page-context-nav--header,
  li > a
    padding: 0.333em 0.666em
    // margin: 0 0.333em
  .page-context-nav--header
    padding-left: 1px

  ul, li
    list-style: none

  li,
  a
    transition: background-color 0.3s, border-color 0.3s, color 0.3s

  li
    // display: inline
    padding: 0
    margin: 0
    display: inline-flex
    position: relative
    margin-right: 0.666em

    &::before
      content: ""
      width: 1px
      background-color: rgba(255,255,255, 0.5)
      height: auto
      margin: 0.1666em 0
      transform: translateX(-0.33333em)
      display: inline-block
      position: absolute
      left: 0
      top: 0
      bottom: 0
      transition: background-color 0.3s

    a
      display: inline-block
      border-radius: 4px

  ul
    margin: 0
    padding: 0
    display: inline-flex
    // display: contents
    // flex-direction: column
    justify-content: flex-start
    overflow: hidden
    flex: 1 1 66%

    &:hover, &:focus
      li::before
        background-color: transparent

  li
    &:hover, &:focus
      a
        // background-color: rgba(white, 0.38)
        // background-color: $secondary
        // color: white
        text-decoration: underline

  a
    color: inherit
    &::after
      content: ">"

  summary
    list-style-image: none
    // text-indent: -1em
    &:focus
      outline: 0 none
    &::-webkit-details-marker
      display: none

  @media (max-width: 444px)
    height: 44px
    display: flex
    position: relative
    align-content: center

    .page-context-nav--header
      position: absolute
      height: 44px
      padding: 0 1.333em
      line-height: 44px
      top: 0
      left: 0
      right: 0
      display: flex
      align-content: center
      align-items: center
      box-sizing: border-box
      width: 100%
      &:focus
        outline: 0 none
      &:focus-visible
        outline: 0 none
    .page-context-nav--toggle
      position: absolute
      right: 0
      top: 0
      width: 44px
      height: 44px
      z-index: -1
      padding: 0
      display: table-cell
      text-align: center
      vertical-align: middle
      &::after
        content: "︙"
        display: block
        transition: transform 0.3s

    .page-context-nav--list-container
      display: block
      background-color: white
      padding: 0 0.666em
      left: 0
      right: 0
      top: 43px
      position: absolute
      max-height: 0
      transition: max-height 0.3s, box-shadow 0.3s
      overflow-y: hidden
      box-sizing: border-box
      z-index: 2

      ul
        padding-bottom: 0.333em
      li
        margin-bottom: 0.333em
        flex-basis: 100%
      a
        width: 100%

    .page-context-nav--header:focus

      & ~ .page-context-nav--toggle
        z-index: 3
        &::after
          // content: "⋯"
          transform: rotate(-90deg)
          z-index: -1

      & ~ .page-context-nav--list-container
        max-height: 100vh
        overflow-y: initial
        z-index: 2
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2)
        ul
          // opacity: 1

  @include tablet
    justify-content: center
    flex-wrap: nowrap
    .page-context-nav--header
      flex-shrink: 0
    .page-context-nav--list-container
      ul
        flex: 0 1 auto
