@charset "utf-8"

h1,
h2,
h3
  line-height: 1.1 !important

pre
  padding: 20px
  overflow-x: scroll
  margin-bottom: 1em

h1
  font-size: 3.5rem
  margin-bottom: 1.5rem

  @include mobile
    font-size: 2.2rem

h2
  font-size: 2.75rem
  margin-bottom: 1.5rem

  @include mobile
    font-size: 1.75rem

h3
  font-size: 1.9rem
  margin-bottom: 1.5rem

  @include mobile
    font-size: 1.5rem

h4
  font-size: 1.65rem
  margin-bottom: 1.5rem

  @include mobile
    font-size: 1.333rem

h5
  font-size: 1.45rem
  margin-bottom: 1.5rem

  @include mobile
    font-size: 1.25rem

h6
  font-size: 1.2rem
  margin-bottom: 1.5em

  @include mobile
    font-size: 1.125rem

p
  margin-bottom: 1.5rem
  &:last-child
    margin-bottom: 0

a
  color: $primary

main
  ul, ol
    margin-left: 1em
    margin-top: 0.66em

  ul
    list-style: initial
    ul
      list-style: circle
      ul
        list-style: square
        ul
          list-style: disc

  li
    padding-left: 0.66em
    margin-bottom: 0.66em
    list-style: inherit

    // ul
    //   list-style: circle

    //   ul
    //     list-style: square

[role="trademark"], sup[role="trademark"]
  font-size: 100%
  top: 0
  line-height: inherit

.entry-content blockquote
  padding: 0.5em 6.75% 1em
  font-style: italic
  font-size: 1.1em
  line-height: 1.45
  font-weight: 200
  position: relative
  &::before, &::after
    font-weight: 900
    position: absolute
    font-size: 7em
    line-height: 1
    opacity: 0.125
  &::before
    content: "“"
    left: -1rem
    top: 0
  &::after
    content: "”"
    right: -0.4rem
    bottom: -0.4em
  p
    margin-bottom: 0.7em
    text-indent: 2em
    &:first-of-type
      text-indent: 0
