@charset "utf-8"

.post
  .cat-links,
  .tags-links,
  .comments-link
    margin-right: 0.5em
  .archives &
    margin-bottom: 40px
    &:last-child
      margin-bottom: 0

body
  --post-type: ''
  &.single-post
    --post-type: ' Post '

.post-navigation
  // .nav-links
  //   display: flex
  //   justify-content: space-between
  //   align-items: flex-start
  //   margin-top: 2rem
  //   padding-top: 2rem
  //   border-top: thin solid #ddd
  //   > *
  //     font-size: 0.875em
  //     border: thin solid $primary
  //     padding: 0.62em 1.22em
  //     flex: 0 1 48%
  //     border-radius: 4px
  // .nav-next
  //   &::before
  //     content: "Next: "
  //   &::after
  //     content: " →"
  // .nav-previous
  //   &::before
  //     content: "← Previous: "
  .nav-links
    display: flex
    justify-content: space-between
    align-items: flex-start
    > *
      font-size: 0.875em
      color: $primary
      flex: 0 1 auto
      a
        display: block
        &:hover
          text-decoration: underline
          color: inherit
          &::before
            text-decoration: none
        &::before
          content: ""
          display: block
          width: max-content
          border: thin solid $primary
          padding: 0.62em 1.22em
          border-radius: 4px
          margin-bottom: 1rem
          font-size: 1rem

  .nav-next
    a
      text-align: right
      &::before
        margin-left: auto
        content: "Next " var(--post-type) "→"
    // a::after
    //   content: " →"
  .nav-previous
    a::before
      content: "← Previous" var(--post-type)

.archive:not(.post-type-archive-product)
  #main
    display: grid
    grid-gap: 12vh 4vw
