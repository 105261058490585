@charset "utf-8"

/* Text meant only for screen readers. */
.screen-reader-text
	clip: rect(1px, 1px, 1px, 1px)
	position: absolute !important
	height: 1px
	width: 1px
	overflow: hidden
	word-wrap: normal !important /* Many screen reader and browser combinations announce broken words as they would appear visually. */

.screen-reader-text:focus
	background-color: #f1f1f1
	border-radius: 3px
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6)
	clip: auto !important
	color: #21759b
	display: block
	font-size: 14px
	font-size: 0.875rem
	font-weight: bold
	height: auto
	left: 5px
	line-height: normal
	padding: 15px 23px 14px
	text-decoration: none
	top: 5px
	width: auto
	z-index: 100000 /* Above WP toolbar. */

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus
	outline: 0
