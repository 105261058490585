@charset "utf-8"

.page-header,
article.course > header,
.archive-header
	background-color: #fff
	background-repeat: no-repeat
	background-size: cover
	background-position: center
	color: #171717
	padding-top: 1.5rem
	padding-bottom: 1.5rem
	overflow: hidden
	padding-top: $section_padding
	padding-bottom: $section_padding

	@media screen and (max-width: 450px)
		color: #000000

	.home &
		@media screen and (max-width: 450px)
			background-position-x: left !important
			background-size: 200% !important

	&.with-background,
	&.hero
		position: relative
		z-index: 0
		// max-height: calc(100vh - 101px)
		padding-top: $section_padding_double
		padding-bottom: $section_padding_double

		&::before
			content: ''
			display: block
			position: absolute
			top: 0
			right: 0
			bottom: 0
			left: 0
			background-image: linear-gradient(to bottom, #ffffff55, #ffffff33, #ffffff99)
			@include mobile
				background-image: linear-gradient(to bottom, #ffffff55, #ffffff33 33%, #ffffff33 66%, #ffffff99)
			.home &
				background-image: linear-gradient(to bottom, #ffffff00, #ffffff55)
				@include mobile
					background-image: linear-gradient(to bottom, #ffffff22, #ffffff00 33%, #ffffff00 66%, #ffffff55)

	&.with-background
		min-height: calc(38vh - 101px)
		@include mobile

	&.hero
		min-height: calc(62vh - 101px)

	.container
		display: flex
		flex-direction: row
		flex-wrap: wrap
		align-content: center
		align-items: center

		& > *
			flex-basis: 100%

.hero_background_image_container
	position: absolute
	left: 0
	right: 0
	bottom: 0
	top: 0
	width: 100%
	height: 100%
	display: flex
	flex-direction: column

.hero_background_image[class]
	position: absolute
	top: 0
	right: 0
	bottom: 0
	left: 0
	object-fit: cover
	width: 100%
	height: 100%
	z-index: -1
	.home &
		object-position: top center
		@include from($tablet)
			object-fit: contain
			object-position: center right

.page-subtitle
	font-size: 30px

	@include mobile
		font-size: 24px

.flex-center
	display: flex
	align-content: center
	align-items: center

$white-glow: 0 0 1em white
$white-glow-thin: 0 0 0.6em white

.page-title
	text-shadow: $white-glow, $white-glow
	@include mobile
		text-shadow: $white-glow, $white-glow, $white-glow, $white-glow

.page-subtitle
	text-shadow: $white-glow, $white-glow-thin, $white-glow-thin, $white-glow-thin, $white-glow-thin
	@include mobile
		text-shadow: $white-glow, $white-glow, $white-glow, $white-glow-thin, $white-glow-thin, $white-glow-thin, $white-glow-thin, $white-glow-thin

.page-header--call-to-action a
	@media screen and (max-width: 450px)
		box-shadow: $white-glow

.hero__entry-title
	margin: 0
	padding-right: 10vw
	line-height: 1.2
	font-weight: bold
	font-size: 45px

	@include mobile
		font-size: 35px

	.with-title-image &
		padding-right: 0
		text-align: center

.with-content-underline .container > :last-child
	position: relative
	padding-bottom: 16px

	// // underline
	&::after
		content: ""
		border-bottom: thin solid $primary
		width: 25%
		height: 1px
		display: block
		position: absolute
		bottom: 0
		left: 0

.hero.is-primary a.nav-item,
.hero.is-primary .nav-item a:not(.button)
	color: rgba(255, 255, 255, 1)

@include mobile
	.home
		.hero_background_image
			object-position: top left
			// object-fit: contain

		.hero_background_image_container
			width: 200%
			height: 200%
			right: auto
			bottom: auto

		.page-header.hero
			padding-bottom: calc((8vh + 8vw) * 2)
