.post-entries
  margin: 3rem -1rem
  display: flex
  width: calc(100% + 2rem)
  justify-content: space-between
  border: thin solid #eeeeee
  border-style: solid none

  .nav-next,
  .nav-prev,
  a
    display: inline-block

  .nav-next,
  .nav-prev
    margin: 1rem

    .button
      margin-top: 1rem

  .nav-next
    text-align: right

.post-password-form
  border: medium solid $primary
  padding: 1.5rem 2rem 0.5rem
  border-radius: 0.66rem
  background-color: fade-out($primary, 0.96)
