@charset "utf-8"

// Breakpoints
// $breakpoints:
//     s: 450px,
//     m: 768px,
//     l: 1024px,
//     xl: 1600px,
//     max: 1920px

$m: 805px
$l: 1024px
$baseline: 1rem

=x( $size )

  @media screen and ( min-width: $size )
    @content

@function px-rem($px)
  @return $px / 16 * 1rem

// Return unit as rem value
@function rem( $unit )
  @return #{$unit}rem

//  Get max line width
@function line_width( $size: $base-font-size )
  @return rem( $size * 38 )

// Get baseline as rem
@function get_baseline( $size: 10 )
  @return rem( $baseline / $size )

//  MEDIA QUERIES
// =x($size)
//   $value: ""

//   @if type-of($size) == "number"
//     $value: #{$size}px
//   @else
//     $value: map-get($breakpoints, $size)

//   @media screen and (min-width: #{$value})
//     @content

=y
  @media screen and (orientation: landscape)
    @content

// RESPONSIVE RATIO
=ratio($x, $y)
  padding-top: unquote($y / $x * 100 + "%")

//  TRUNCATE
=truncate($overflow: ellipsis)
  overflow: hidden
  white-space: nowrap
  text-overflow: $overflow

//  PSEUDO
=before($include: true, $position: absolute, $content: "")
  &::before
    @if $include
      content: $content
      display: block
      position: $position

    @content

=after($include: true, $position: absolute, $content: "")
  &::after
    @if $include
      content: $content
      display: block
      position: $position

    @content

=object-fit($x: 50%, $y: 50%)
  width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0
  object-fit: cover
  object-position: $x $y
  font-family: 'object-fit'

// Last Child bottom styling removal
=last-child($padding: false)
  &:last-of-type
    @if $padding == false
      margin-bottom: 0
    @else
      margin-bottom: 0
      padding-bottom: 0

    @content
