@charset "utf-8"

.section
  background: none
  margin: 1rem 0
  &::after
    content: ''
    display: block
    clear: both
  &.no-padding-top
    padding-top: 0 !important

.panel
  border-radius: initial
  box-shadow: initial
  font-size: initial
