.site-nav-primary
  .inner-anchor svg
    margin-left: 0.33em

  .menu-item.active
    > a
      position: relative
      .inner-anchor
        &::after
          content: ''
          display: block
          position: absolute
          height: 100%
          width: 8px
          left: 0
          top: 0
          bottom: 0
          background-color: $primary
  @include mobile
    border-top: thin solid transparentize( $primary, 0.76 )

    & + *
      border-top: thin solid transparentize( $primary, 0.68 )

  @include tablet
    padding-top: 1px // for border
    height: auto
    order: 1
    flex-basis: calc(100% + 15px)
    margin: 0 calc((#{$nav-primary-button-padding} - ((#{$line-height-tablet} - 2rem) / 2)) * -0.5)

    > .menu-item
      &, & > a
        flex-shrink: 0

      &.focus
        > .sub-menu
          left: auto
          margin-left: -10px

        > a
          font-weight: 700
          background-color: white
          outline: 0 none

      &.active
        position: relative
        > a
          position: revert
          .inner-anchor
            &::after
              width: revert
              height: 3px
              bottom: 0
              top: revert
              left: revert
              transition: left 0.3s, right 0.3s
              left: $nav-primary-button-padding
              right: $nav-primary-button-padding
          &:hover .inner-anchor::after
            left: 0
            right: 0

    &::after
      border-top: thin solid transparentize( $primary, 0.84)
      content: ""
      width: 100vw
      position: absolute
      // z-index: 1 // only needed if no parent top padding
      top: 0
      left: 50%
      right: 50%
      margin-left: -50vw
      margin-right: -50vw

  @media (min-width: 769px) and (max-width: 820px)
    a, svg
      font-size: 0.92em
