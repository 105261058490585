@charset "utf-8"

%button
  line-height: 1
  background: none
  outline: 0
  border: thin solid $primary
  height: auto
  width: auto
  padding: 1rem 1.5rem
  margin: 0
  color: $primary
  font-weight: 300
  font-size: 1rem
  border-radius: 4px
  position: relative
  z-index: 1
  cursor: pointer
  white-space: normal
  -webkit-appearance: none
  &:active,
  &:hover,
  &:focus
    text-decoration: underline
    background: lighten($primary, 95%)
  @include mobile
    width: 100%
    font-size: 0.9rem

%button-filled
  background: $primary
  color: #fff
  &:active,
  &:hover,
  &:focus
    background: lighten($primary, 5%)
  mark, ins
    background: transparent
    font-weight: bold

.button,
.single_add_to_cart_button,
.woocommerce a.button,
#mc_signup_submit.button
  @extend %button
  &.filled
    @extend %button-filled

.button.fill, .button.filled,
a.checkout-button.button.alt.wc-forward,
#mc_signup_submit.button,
.nav-next .button
  @extend %button-filled

a.button
  color: $primary

.post-comment-button
  padding-left: 2em
  padding-right: 2em

