@charset "utf-8"

section.testimonials
	position: relative

	background-size: cover
	text-align: center

	h2
		margin: 0 auto 2rem
		padding: 0 1.4rem .7rem
		display: inline-block

		border-bottom: thin solid $primary

	.testimonials-carousel-arrow-prev,
	.testimonials-carousel-arrow-next
		width: 60px
		height: 20px
		position: absolute
		bottom: -30px
		cursor: pointer

		svg
			height: 100%
			fill: $white
			filter: drop-shadow( 0 0 2px rgba(0, 0, 0, .7))

	.testimonials-carousel-arrow-prev
		left: 0
		transform: rotate( 180deg )

	.testimonials-carousel-arrow-next
		right: 0

	@include x( $m )

		.testimonials-carousel-arrow-prev,
		.testimonials-carousel-arrow-next
			bottom: 0

.testimonials-wrap
	overflow: hidden

.testimonials-carousel
	position: relative

	text-align: center

	.slick-list
		overflow: visible

	.single
		padding: 60px 8px 0
		outline: 0
		appearance: none

		opacity: .5

		transition: 300ms

		@include x( $m )
			padding-left: 12px
			padding-right: 12px

		&.slick-active
			opacity: 1

		.single-inner
			padding: 30px 0 80px
			position: relative
			display: flex
			flex-direction: column

			.words
				display: flex
				flex-direction: column
				justify-content: center
				flex-grow: 1

			header,
			p
				position: relative
				z-index: 20
				margin-bottom: 1rem
				margin-left: 12px
				margin-right: 12px
				@include x($m)
					margin-left: 16px
					margin-right: 16px

			> *
				position: relative
				z-index: 20
				text-align: center

			&::before
				content: ''
				display: block
				position: absolute
				width: 100%
				height: 100%
				top: 0
				left: 0

				background-color: $white

		.emblem
			width: 120px
			height: 120px
			padding: 16px
			position: absolute
			left: 50%
			transform: translate( -50%, -90px )
			z-index: 10

			border-radius: 60px
			background-color: $white

			img
				width: 60px
				margin: 0 auto
				display: block

		.testimonial-title
			font-weight: bold

		.name
			width: 100%
			padding: 12px
			display: block
			position: absolute
			bottom: 0
			left: 0
			right: 0
			margin-left: 0
			margin-right: 0
			margin-top: 1rem
			background-color: $primary
			color: $white

			@include x( $m )
				padding: 12px 16px

		&:last-of-type
			margin-right: 0

		@include x( $m )
			padding: 60px 18px 0

	.slick-dots
		margin: 20px auto 0
		display: inline-block

		li
			margin-right: 16px
			display: inline

			button
				width: 10px
				height: 10px

				font-size: 0
				border: thin solid $white
				border-radius: 5px
				background-color: transparent
				filter: drop-shadow( 0 0 2px rgba(0, 0, 0, .7))

			&.slick-active

				button
					background-color: $white

			&:last-of-type
				margin-right: 0
