@charset "utf-8"

.site-footer

	.footer-inner
		margin-bottom: 0
		padding: 50px $viewport_margin 400px

		background: $primary url( ../../assets/images/flower-purple.jpg ) 50% 103% no-repeat
		background-size: 100% auto

		.container
			margin-bottom: 0
			display: flex
			flex-wrap: wrap
			align-content: space-between
			justify-content: space-between
			@media screen and (min-width: 1024px)
				justify-content: flex-start
				max-width: calc(960px + 40px)
			@media screen and (min-width: 1216px)
				max-width: calc(1152px + 40px)
			@media screen and (min-width: 1240px)
				max-width: calc(1176px + 40px)

		.footer-logo-acreds
			order: 1
		.footer-social-links
			order: 2
		.footer-nav-wrap
			order: 3
		.footer-legal-nav-wrap
			order: 4

		.footer-logo-acreds
			margin-bottom: 40px
			padding: 0
			display: flex
			flex-wrap: wrap
			align-content: flex-start
			align-items: flex-start

			width: 50%
			max-width: 320px

			svg
				width: 50%
				flex: 0 1 auto
				display: inline-block

				&:first-of-type
					width: 100%
					margin-bottom: 30px
					margin-left: -6px

				&:nth-of-type(2)
					width: 30%
					margin-right: 10%

				&:nth-of-type(3)
					width: 50%

		h4
			margin-bottom: 30px

			color: $white
			font-size: 18px
			font-weight: 700

			@include mobile
				font-size: 1rem

		.footer-nav-wrap
			margin-bottom: 40px

		nav, ul

			li
				margin-bottom: 16px

			a
				color: $white
				font-size: 18px
				font-weight: 300

				@include mobile
					font-size: 1rem

				&:hover
					color: darken( $white, 10% )

		@media screen and (max-width: 560px)
			background-size: 160% auto

			.container
				// flex-direction: column

				> div
					width: auto
					min-width: 100px
					max-width: 224px

				.footer-logo-acreds
					// margin: 10px
					margin-left: 4px

				.footer-social-links
					min-width: 44px
					max-width: 100px
					margin-bottom: 10px
					flex-basis: 64px

				.footer-nav-wrap
					flex-basis: 128px
					flex-shrink: 2

				.footer-legal-nav-wrap
					flex-basis: 200px
					flex-shrink: 1

		@include x( $m )

			background-size: 80% auto

			.container
				flex-wrap: nowrap

				> div
					margin: 10px

			.footer-logo-acreds
				width: 30%
				padding: 0

			.footer-social-links
				order: 5

		@include x( $l )

			padding: 30px $viewport_margin 20px

			background-position: 133% 0%
			background-size: auto 125%

			padding: 10px calc(#{$viewport_margin_calc} - 20px)

			.container
				min-height: calc(15vh + 15vw)
				// padding-right: 33%
				> div
					margin: 20px
			.footer-logo-acreds
				margin-bottom: 0
				margin-left: 14px
				min-width: 240px
			.footer-social-links
				order: 2
				font-size: 36px
				padding-bottom: 0
			// .footer-nav-wrap
			// 	padding-bottom: 30px
			// .footer-legal-nav-wrap
			// 	padding-bottom: 30px

			h4
				margin-bottom: 30px

				font-size: 16px

			nav
				li
					margin-bottom: 8px

				a
					font-size: 14px

	.site-copyright
		// width: calc( 100% + 40px )
		// margin: 0 -20px
		padding: 8px 0

		background-color: $white
		color: $black
		text-align: center

		@include x( $l )
			padding: 4px 0

.footer-social-links
	font-size: 44px
	a
		color: white
		opacity: 0.85
		&:hover
			opacity: 1
