@charset "utf-8"

.content
	& > .hero-body
		padding-left: 0
		padding-right: 0

.site-main
	> section
		background: #fff

.entry-content
	& > p
		&:first-of-type:not(:last-of-type)
			font-weight: 600

section.content-block-inset,
section.with-background
	position: relative
	> .background-image
		z-index: -1
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0
		img
			object-fit: cover
		&,& img
			width: 100%
			height: 100%
		// background-repeat: no-repeat
		// background-size: cover
		// background-position: center

.content-block,
.content-block-inset
	@media print, screen and (min-width: 769px)
		.column.is-two-thirds,
		.column.is-two-thirds-tablet
			flex: 1 1 auto

.content-block
	@include mobile
		padding: 1.5rem

.content-block-inset
	@include mobile
		.inset
			margin-top: calc(-1.5rem + 7.5vw + 7.5vh)

.content_block--image
	margin-left: auto
	margin-right: auto
	max-width: 100%
	max-height: 100%
	height: calc(15vh + 15vw)
	width: calc(15vh + 15vw)
	display: flex
	justify-content: stretch
	// align-items: center
	display: inline-flex
	align-items: flex-start

	@include mobile
		width: 400px
		max-width: 100%

	img
		// height: 100%
		width: 100%
		object-fit: contain

		&.wpsmartcrop-image
			object-fit: cover

		.content-block-inset &
			background-color: #fffd
			box-shadow: 0 0.25rem 0.5rem #0002
			height: auto
			// width: auto
			// max-width: 100%
			max-height: 100%
			object-fit: cover
			// position: absolute
			// top: 0
			// left: 0
			width: 100%

.content-block-inset .inset
	background: #fff
	padding: 3rem
	box-shadow: 0 0 40px rgba(0,0,0,0.02)

	@include x($m)
		margin-right: 2rem

	@include mobile
		flex-grow: 1
		padding: 1.5rem

	& .columns
		display: flex

		@include mobile
			flex-direction: column-reverse

.content-block__title
	position: relative
	margin: 0 0 1rem 0
	padding: 0 0 1rem 0
	line-height: 1.1
	&::after
		content: ""
		border-bottom: thin solid $primary
		width: 50%
		position: absolute
		bottom: 0
		left: 0
	.content-block-inset &
		@media screen and (min-width: 480px) and (max-width: 767px)
			margin-right: calc(15vh + 15vw + 1.5rem)

.content-block-inset__image
	order: 1
	margin-top: -5rem
	margin-right: -5rem
	@include mobile
		order: 2
		margin-top: calc((1.5rem + 7.5vh + 7.5vw) * -1)
		margin-right: 0
	@media screen and (min-width: 480px) and (max-width: 767px)
		max-width: calc(15vh + 15vw + 1.5rem)
		align-self: flex-end
		margin-bottom: -4.25rem

.content-block__cta button
	border: thin solid $primary

@include mobile
	.content-block
		padding: 0

.post-type-archive-resource
	article + article
		margin-top: $section_padding

.wp-embed-aspect-16-9
	.wp-block-embed__wrapper
		height: 0
		padding-bottom: 56.25% // 9 / 16 * 100
		position: relative

		iframe
			position: absolute
			height: 100%
			width: 100%
			max-height: calc(100vh - 100px)

article .entry-meta
	font-size: 90%
	opacity: 0.9

.archive article .entry-meta
	border-top: thin solid #4a4a4a77
	padding-top: 0.66em
	margin-top: -0.66em
	display: inline-block

.archive .meta-label
	display: none

.wp-block-group
	margin-bottom: 1.5rem
	& + h3
		clear: none

.wp-block-group.has-background
	padding: 1rem 1.5rem

.entry-content * + h3
	margin-top: 1.5rem
