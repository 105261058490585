@charset "utf-8"

section.carousel
	position: relative

	h2
		margin-bottom: 40px
		padding-bottom: 20px
		position: relative

		&::after
			content: ''
			display: block
			position: absolute
			border-bottom: thin solid $primary
			bottom: 0
			left: 0
			width: 66%

	@include x( $m )
		h2
			&::after
				width: 33%

%carousel
	position: relative
	// z-index: 2
	border-bottom: thin solid #0000

	.prop
		width: 100%
		min-height: 320px
		height: auto
		// max-height: calc(100vh - 270px);
		// mask-image: linear-gradient(to top, #0000, #000 20%)
		// z-index: 3

		background-color: $white

		.image
			width: 100%
			margin-bottom: 20px
			padding-top: 45%
			overflow: hidden

			img
				width: 100%
				height: 100%
				position: absolute
				top: 0
				left: 0

				object-fit: cover

		h3
			padding-bottom: 18px
			margin-bottom: 20px

			border-bottom: thin solid $primary

			a
				color: $black

				&:hover
					color: $primary

			@include mobile
				padding-bottom: 0
				border-bottom: 0 none

		p
			@include mobile
				display: none
			// max-height: 72px // 3 lines
			// overflow: hidden

		& > a.image-link
			display: block

			transition: all 800ms ease

	.slick-dots
		margin-left: 0
		position: absolute
		bottom: 0
		left: 50%
		display: flex
		justify-content: center
		transform: translateX( -50% )
		// z-index: 4

		li
			width: 20px
			height: 48px
			margin: 0 8px 0 0
			position: relative
			cursor: pointer
			list-style: none

			button
				width: 10px
				height: 10px
				display: block
				position: absolute
				top: 50%
				left: 5px
				transform: translateY( -50% )
				appearance: none
				outline: none
				cursor: pointer

				background-color: transparent
				border: thin solid $primary
				border-radius: 5px
				font-size: 0

			&.slick-active
				button
					background-color: $primary

			&:last-of-type
				margin-right: 0

	@include x( $m )

		.prop
			height: auto

			p
				max-height: 144px // 6 lines

			a
				display: inline-block

		.slick-list
			width: 65%
			margin-left: 35%

		.slick-dots
			width: 33%
			display: block
			top: 0
			left: 0
			transform: translateX( 0 )
			list-style-type: none !important
			max-width: calc(100% - 2rem - 120px)

			li
				width: auto
				height: auto
				padding: 10px 0 10px 30px
				position: relative
				list-style: none

				button
					width: auto
					height: auto
					padding: 0
					position: relative
					top: auto
					left: auto
					transform: none

					border-radius: 0
					border: none
					background-color: transparent
					font-size: 14px
					text-align: left

					transition: 300ms

					&::before
						content: ''
						display: block
						position: absolute
						width: 11px
						height: 11px
						top: 50%
						left: -30px
						transform: translateY( -50% )

						border-radius: 6px
						background-color: $primary

				&::after
					content: ''
					display: block
					position: absolute
					width: 3px
					height: 100%
					top: 0
					left: 4px

					background-color: $primary

				&:first-of-type
					&::after
						height: 50%
						top: 50%

				&:last-of-type
					&::after
						height: 50%

				&.slick-active

					button
						font-weight: 700
						background-color: transparent

						&::before
							transform: translateY( -50% ) scale( 1.5 )

.article-carousel,
.video-carousel
	@extend %carousel

	@include mobile
		padding-bottom: 60px

.article-carousel-arrow-prev,
.article-carousel-arrow-next,
.video-carousel-arrow-prev,
.video-carousel-arrow-next
	width: 60px
	height: 48px
	position: absolute
	bottom: 0
	// z-index: 5
	display: inline-flex
	cursor: pointer

	svg
		height: 100%

		fill: $primary

	@include x( $m )
		display: none

.article-carousel-arrow-prev,
.video-carousel-arrow-prev
	left: 0
	transform: rotate( 180deg )

.article-carousel-arrow-next,
.video-carousel-arrow-next
	right: 0

.video-carousel
	.prop
		min-height: 0

		.video
			width: 100%
			margin-bottom: 1rem
			padding-top: 56%
			position: relative

			@media (max-width: 767px)
				margin-bottom: 1.5rem

			iframe
				width: 100%
				height: 100%
				margin: 0
				position: absolute
				top: 0
				left: 0

		h3
			border-bottom: none

	@include x( $m )

		.prop
			h3
				display: none

		.stage-map
			top: 0

.testimonials-carousel.slick-initialized
	.slick-track
		display: flex
		align-items: stretch
	.slick-slide
		height: auto
	.single-inner
		height: 100%
		p:last-of-type
			margin-bottom: 0

.testimonials-carousel .single .single-inner::before
	background-color: $white
	background: linear-gradient(to bottom, $white, #fffd)
