@charset "utf-8"

$nav-primary-button-padding: 10px
$line-height: 1em
$line-height-tablet: 1.25rem

.main-navigation
  width: auto
  display: flex
  float: none
  // order: 3

  @include mobile
    // flex: 0 1 120px
    display: flex
    flex-direction: column
    order: 1
    width: 100%
    flex-basis: 100%
    max-height: 0
    transition: max-height 0.3s, box-shadow 1s 0.3s
    overflow: hidden
    position: absolute
    top: 90px
    background-color: white
    left: auto

    .nav-is-visible &
      max-height: calc(100vh - 90px)
      box-shadow: 0 30px 30px rgba(30, 30, 30, 0.1)
      overflow-y: scroll

    .nav-menu
      order: 1
      // display: flex
      // flex-direction: column
      // justify-content: flex-start
      background-color: white

    .nav-menu, .sub-menu, .menu-item
      display: block
      width: 100%

  @include tablet
    order: initial
    display: contents

  .menu-item
    &:hover:not(.focus) > .sub-menu
      left: -999em

    @include mobile
      & + *
        border-top: thin solid transparentize($primary, 0.84)
      a
        max-width: 100%
        line-height: 1.25

      > .sub-menu
        position: static
        display: none

      // .inner-anchor--text + [class^="icon-"]
      //   margin-left: 0.33em

      &:not(.focus) > a
        .icon-sm--closed
          display: inline-block !important

      &.focus
        > a
          .icon-sm--open
            display: inline-block !important

        > .sub-menu
          display: block

    @include tablet
      &:not(.focus) > a
        .icon-lg--closed
          display: inline-block !important
      &.focus > a
        .icon-lg--open
          display: inline-block !important
  a
    // Ensure link is at least 48x48px in size for easy tap target on mobile
    color: lighten( $black, 10% )
    background-color: white
    font-weight: 500
    text-align: left
    line-height: $line-height
    padding: #{$nav-primary-button-padding + 5px} #{$viewport_margin}
    font-size: 18px

    @include mobile
      .inner-anchor
        display: flex
        justify-content: space-between

    @include tablet
      font-size: 1rem
      text-align: left
      padding: calc(#{$nav-primary-button-padding} - ((#{$line-height-tablet} - 2rem) / 2)) #{$nav-primary-button-padding}
      transition: background-color 0.3s

  @include tablet
    a:hover, a:focus, .menu-item:focus > a
      background-color: $secondary-light
      outline: 0 none

  @include mobile
    .sub-menu
      max-width: 100%

      a
        width: 100%
        font-size: 1rem
        font-weight: 500
        padding-left: #{($nav-primary-button-padding + 10px) * 2}
        background-color: transparentize( $primary, 0.92 )

      .menu-item
        border-top: thin solid transparentize($primary, 0.76)

      .sub-menu
        a
          padding-left: #{($nav-primary-button-padding + 10px) * 3}
          background-color: transparentize( $primary, 0.84 )
        .menu-item
          border-top-color: transparentize($primary, 0.68)
    .focus
      > .sub-menu
        box-shadow: inset 0 3px 3px rgba(30, 30, 30, 0.10)
        box-shadow: inset 0 3px 3px rgba(30, 30, 30, 0.10), inset0 -3px 3px rgba(30, 30, 30, 0.04)
      + .menu-item
        border-top-color: transparentize($primary, 0.68)

  @include tablet
    height: 100%
    flex-grow: 1

    .nav-menu > .menu-item,
    .menu-item-has-children
      > a
        > .inner-anchor
          display: flex
          justify-content: space-between
          align-items: center
          width: 100%

    .nav-menu
      position: relative
      justify-content: space-between
      flex-wrap: nowrap

      .menu-item
        display: flex
        align-items: center

        .inner-anchor--text
          position: relative
          top: 1px

        a
          font-weight: 300

        > a:hover,
        &.focus > a
          .inner-anchor
            &::after
              left: 0
              right: 0

      > .menu-item:last-child
        &.focus
          > ul
            left: initial
            right: -10px

        > ul li a
          text-align: right
          justify-content: flex-end

      // a
        // color: lighten( $black, 10% )
        // font-size: 1rem

        // &:hover
          // color: $primary

    .sub-menu
      @include tablet
        box-shadow: 0 7.5px 7.5px rgba(30, 30, 30, 0.15)
      // max-height: calc(100vh - 135px)
      // overflow-y: auto
      // position: fixed
      // top: 136px
      // width: 320px
      a
        display: flex
        align-items: center
        min-height: 57px
        width: 320px
        padding-left: calc(#{$nav-primary-button-padding} * 2)
        padding-right: calc(#{$nav-primary-button-padding} * 2)
      > .menu-item + *
        border-top: thin solid mix($primary, $white, 16%)

// Small menu
@include mobile
  .nav-is-visible .main-navigation ul
    // flex-direction: column
    // justify-content: center
    width: 100%

  .menu-toggle
    width: auto
    padding-left: $viewport_margin
    padding-right: $viewport_margin
    min-width: 44px
    min-height: 44px
    display: inline-flex
    flex-direction: row
    justify-content: center
    align-items: center
    font-size: 1rem
    font-weight: 300
    line-height: 1
    background-color: white
    border: 0 none
    color: $primary
    flex: 0 1 auto

    &[aria-expanded="true"]
      .menu-toggle--open
        display: none
      .menu-toggle--close
        display: block !important

    svg
      margin-left: 0.66rem

@include tablet
  .menu-toggle
    display: none

  .main-navigation ul
    display: flex
    flex-wrap: wrap
    &.dropdown-menu
      display: none
      position: absolute
      top: $navbar-height
      right: 0

// .navbar-menu
//   &.is-active
//     position: absolute
//     width: 100%
//     z-index: 200

// .focus > .inner-anchor svg
  // transform: rotate(180deg)
  // transition: transform 150ms ease
// .site-header .cart
//   padding-left: 0 !important
//   margin-right: 0 !important
