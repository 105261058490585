ul.sensei-course-filters,
ul.course-container
  margin-left: 0
  & > li
    list-style: none

.course_archive_tools
  margin: 0.5em -1em calc(#{$seed_calc})
  width: calc(100% + 2em)
  display: flex
  align-items: center
  justify-content: space-between
  border-color: #4a4a4a22
  padding: 1em 0
  border-style: solid
  border-width: thin 0

  & > *[class]
    margin: 0 1em

ul.sensei-course-filters[class]
  order: -1
  margin-left: 0.66em
  margin-right: 0.66em
  & li
    display: inline
    & a
      display: inline-block
      padding: 0.66em
      margin: 0 0.33em
      background-color: transparent
      border: thin solid #251D72
      border-radius: 0.33em
      line-height: 1
      color: #251D72
      transition: 0.33s all

      &.active,
      &:hover
        background-color: #251D72
        color: white

      &:hover
        opacity: 0.66

ul.course-container
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(280px, 72ex))
  justify-content: center

.course .course-content .entry
  display: flex
  flex-wrap: wrap

  & > *
    flex-basis: 100%

  & > .sensei_course_image
    order: -1
    width: 100%
    height: 0
    position: relative
    padding-bottom: 38%
    overflow: hidden
    margin-bottom: 1.5rem

    & a > img
      position: absolute
      content: ""
      top: 0
      right: 0
      bottom: 0
      left: 0
      width: 100%
      height: 100%
      object-fit: cover

.content-area
  .single-lesson &
    display: grid
    grid-gap: 2rem
    margin-top: 4rem
    grid-template-areas: "main"
    @include from($tablet)
      &.lessons-aside
        grid-template-columns: minmax(280px, 1fr) 1.618fr
        grid-template-areas: "side main"
    main
      grid-area: main
    .lesson-title
      margin-bottom: 2rem
      h1
        font-size: 2.25rem
    .entry
      > p,
      > ul,
      > ol,
      > h2,
      > h3,
      > h4,
      > h5,
      > h6,
      > blockquote,
      > figure,
      > img,
      > iframe,
      > .video,
      > .audio,
      > .download
        margin-top: 2.5rem
        margin-bottom: 2.5rem
      h2,
      h3
        margin-top: 3rem
    .video
      width: 100%
      height: 0
      position: relative
      padding-bottom: 56.25%
      overflow: hidden
      margin-bottom: 2.5rem
      background-color: #eee

      iframe
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0
        width: 100%
        height: 100%
        max-height: calc(100vh - 90px)

.single-course
  article.course
    margin-top: 80px
    display: grid
    grid-gap: 30px

    & > .sensei_course_image
      height: auto

    @include from($tablet)
      grid-template-columns: repeat(12, 1fr)

      & > *
        grid-column-end: span 12
        order: 99

        &:empty
          display: none

      & > .sensei_course_image,
      & > .sensei_course_action
        grid-column: 1 / span 6
        order: 1
        grid-row: 1 / span 4

      & > header:first-of-type,
      & > .course-meta,
      & > .entry
        grid-column: 7 / span 6
        order: 2

    & > .sensei_course_action
      z-index: 2
      align-self: center
      justify-self: center
      background-color: white
      line-height: 1
      border: 1pt solid transparent
      transition: 0.3s all

      &::after
        content: "➔"
        padding-left: 0.444em
        vertical-align: middle
        font: inherit

      &:focus, &:hover
        background-color: transparent
        color: white
        border-color: white
        transform: scale(1.125)
        outline: 0 none

    & > header:first-of-type
      display: flex
      align-items: center
      padding: 0
      h1
        font-size: 2.25rem
      > *
        margin: 0

    & > .course-meta
      font-size: 0.80rem
      padding: 0
      // margin: -1rem 0 0
      // &::before
      //   content: "Placeholder text | Placeholder text"
      &::after
        display: block
        height: 1rem
        content: ""
        width: 61.8%
        border-bottom: thin solid $primary

  .module
    padding-left: 6.5rem
    padding-top: 1.75rem
    position: relative

    &::before
      position: absolute
      left: 0
      top: 0
      color: #cecece
      content: "➔"
      font-size: 3rem
      text-align: center
      line-height: 5rem
      width: 5rem
      height: 5rem
      border-radius: 50%
      display: inline-block
      border: 0.33rem solid #cecece
    > header > *
      font-size: 1.8rem

    ul
      margin-left: 0
      border: 0 solid #eeeeee
      border-width: thin 0
    li
      list-style: none
      padding: 0.66em 0 0.33em
      font-size: 1em
      list-style-position: inside
      line-height: 1.66
      & + li
        border-top: thin solid #eee
      a
        color: black

        &::before
          content: "➔"
          display: inline-block
          background-color: #cecece
          color: white
          border-radius: 50%
          width: 1.5rem
          height: 1.5rem
          line-height: 1.5rem
          text-align: center
          font-size: 1.25rem
          padding-top: 0.125rem
          vertical-align: -0.125rem
          margin-right: 0.75em
          transition: 0.3s background-color

        &:hover
          color: $primary
          .preview-label
            text-decoration: underline
          &::before
            background-color: $primary

      .preview-label
        color: $primary
        background: white
        border: thin solid $primary
        border-radius: 4px
        display: inline-flex
        align-items: center
        padding: 6px 1em 4px
        margin: 2px 0.5em -2px

  .modules-title
    display: none

  .module-lessons
    header
      display: none

.sensei_course_image
  position: relative

  & > a
    display: block
    height: 100%
    // height: 0
    // padding-bottom: 56.25%

    // &::before
    //   content: ""
    //   top: 0
    //   left: 0
    //   right: 0
    //   bottom: 0
    //   width: 100%
    //   height: 100%
    //   position: absolute
    //   background-color: $primary
    //   opacity: 0.75
    //   z-index: 2

    @include from($tablet)
      & > img
        float: none
        margin: 0
        display: block
        width: 100%
        position: absolute
        left: 0
        top: 0
        right: 0
        bottom: 0
        height: 100%
        object-fit: cover
        z-index: 1

.lessons-list
  list-style: none
  counter-reset: li

  li
    list-style-position: inside

  h3, h4
    margin: 0

  ul, ol
    // margin-left: 0

.lessons-list--module
  background-color: $primary
  padding: 0
  counter-increment: li

  &:first-child
    border-radius: 4px 4px 0 0

.lessons-list--module-title,
.module-lessons--lesson-link
  height: 48px
  display: flex
  align-items: center
  flex-flow: wrap
  padding: 0.33rem 1rem
  line-height: px-rem(14)
  position: relative
  padding-left: 38px

  &::before
    display: inline-block
    position: absolute
    width: 1rem
    left: 12px
    text-align: center
    font-weight: normal

.lessons-list--module-title
  color: white
  font-size: px-rem(14)
  font-weight: bold

  &::before
    content: counter(li)
    width: 16px
    margin-right: 12px

.lessons-list--module-lessons
  margin: 0
  // width: calc(100% + 0.66rem)
  background-color: white

.module-lessons--lesson
  background: rgb(247,247,247)

  + .module-lessons--lesson
    border-top: thin solid #ececec

  &.lesson-complete
    color: silver

  &.lesson-current
    background-color: fade-out($primary, 0.9)
    color: black

.module-lessons--lesson-title
  font-size: px-rem(12)

.module-lessons--lesson-link
  color: inherit

  &::before
    content: ""
    border: 1px solid #ececec
    color: #ececec
    font-size: 12px
    background-color: white
    border-radius: 50%
    height: 1rem
    line-height: 1rem
    text-align: center
    display: flex
    justify-content: center
    align-content: center

    .lesson-current &
      content: "➔"
      color: $primary
      border-color: $primary

    .lesson-complete &
      content: "✓"
      // font-size: 0.875rem
      color: white
      border-color: white
      background-color: $primary

  &:hover::before
    content: "➔"
    color: #d4d4d4
    border-color: #d4d4d4

  &:visited
    color: inherit

  &:hover,
  &:focus,
  &:active
    background-color: #ececec
    color: $primary

.lesson footer
  text-align: right

.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button
  @extend %button

  &.alt
    @extend %button-filled

#main .course .course-meta .course-start[class]
  margin-top: 0
  @extend %button-filled
