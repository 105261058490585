@charset "utf-8"

%sticky-top
  position: fixed
  top: 0
  left: 0
  z-index: 3

  @supports (position: sticky) or (position: -webkit-sticky)
    position: sticky

=flex-horizontal
  height: 100%
  display: flex
  justify-content: space-between
  align-items: center

=viewport-margins-flex

  padding-left: $viewport_margin
  padding-right: $viewport_margin
  // width: 100vw

  & > div.container
    @include flex-horizontal

%viewport-margins-flex
  @include viewport-margins-flex

.site-header
  @extend %sticky-top

  box-shadow: 0 0 15px rgba( 30, 30, 30, .3 )
  background-color: $white

  @include viewport-margins-flex

  @include mobile
    box-shadow: 0 0 10px rgba( 30, 30, 30, .4 )
    padding: 0

  & > .container
    flex-wrap: wrap
    @include mobile
      align-content: flex-start
      position: static
    @include tablet

  .admin-bar &
    top: 32px

  // .masthead--primary
  //   @include tablet
  //     background-color: white
  //     border-top: thin solid rgba($primary, 0.16)
  //     height: auto

  .site-branding
    a
      display: block

    svg
      width: 125px
      max-height: 60px
      height: 100%
      vertical-align: middle
      position: relative
      left: -2px

      @include tablet
        // height: 60px

      @include tablet
        // height: 60px

    @include mobile
      padding: 15px $viewport_margin

  &::before, &::after
    display: none
