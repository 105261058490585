body
  &:not(.no-sidebar)
    &:not(.page)
      .site-content
        & > .container
          @include x( $l )
            // grid-template: "header header header header" "secondary . primary ."
            // grid-template-columns: [full-start side-start] 0.618fr [side-end main-start] 1vw [content-start] 1.618fr [content-end] 1vw [main-end full-end]
            display: grid

            grid-template: "header header header header" ". primary . secondary"
            grid-template-columns: [full-start main-start] 1vw [content-start] 1.618fr [content-end] 1vw [main-end side-start] 0.618fr [side-end full-end]
            grid-gap: #{$seed}vh #{$seed}vw
  &:not(.page)
    .site-content
      & > .container
        position: relative
        margin-top: 2rem
    .woocommerce-info
      margin-top: 0 !important
    .entry-title
      // margin-bottom: 0.1em
    .entry-meta
      opacity: 0.8
    .entry-footer
      margin-top: 2rem
      padding-top: 0.5rem
      opacity: 0.7
      border-top: thin solid silver
    .site-main article + article
      margin-top: #{$seed * 3}vh

.single
  .page-header
    padding-top: 0
    padding-bottom: 1rem
  .entry-header
    margin: 0 0 2rem
    display: flex
    flex-direction: column
    .entry-meta
      order: -1
      margin-bottom: 1rem
  .hero__entry-title
    padding-right: 0

.site-content
  > .container
    > header
      grid-area: header
      > :last-child
        margin-bottom: 0
      > nav
        margin-bottom: 1rem
      .section-title, .archive-title
        font-size: 2.5em
        color: black
      .archive-title
        font-weight: bold
#primary
  grid-area: primary
  .woocommerce-page &
    grid-column: main

#secondary
  grid-area: secondary

#secondary
  --bg-color-hue: 246
  --bg-color-sat: 59%
  --bg-color-lum: 98%

  --background-color: hsl(var(--bg-color-hue), var(--bg-color-sat), var(--bg-color-lum))

  --edge-color-hue: var(--bg-color-hue)
  --edge-color-sat: var(--bg-color-sat)
  --edge-color-lum: 50%

  // pastel theme
  --border-right-alpha: 0
  --border-right-width: 0

  // contrast theme
  --border-right-alpha: 0.382
  --border-right-width: 1px

  // in-between theme
  --border-right-alpha: 0
  --border-right-width: 0

  border-right: var(--border-right-width) solid hsla(var(--edge-color-hue), var(--edge-color-sat), var(--edge-color-lum), var(--border-right-alpha))

  display: grid !important
  grid-gap: 2vh 2vw
  align-content: start
  overflow: hidden

  @include x( $l )
    // border-left: thin solid black
    display: flex
    flex-direction: column
    align-items: stretch

  .widget
    // pastel theme
    --list-padding-vertical: 0.5rem
    --bg-color-lum: 98%
    --inset-shadow-alpha: 0.03
    --border-width: thin
    --border-left-width: 0
    --separator-alpha: 0.15
    --hover-separator-alpha: 0.2
    --hover-separator-lum: var(--separator-lum)

    // contrast theme

    --bg-color-lum: 100%
    --inset-shadow-alpha: 0
    --border-alpha: 0.382
    --border-width: 0
    --border-left-width: thin
    --separator-alpha: 0.2
    --hover-separator-alpha: 0.5

    // in between theme
    --bg-color-lum: 99%
    --inset-shadow-alpha: 0
    --border-alpha: 0.382
    --border-width: 0
    --border-left-width: 0
    --separator-alpha: 0.2
    --separator-alpha: 1
    --separator-lum: 80%
    // --separator-lum: var(--edge-color-lum)
    --separator-color: hsla(var(--edge-color-hue), var(--edge-color-sat), var(--separator-lum), var(--separator-alpha))
    --hover-separator-sat: 0.5
    --hover-separator-lum: calc(var(--edge-color-lum) / 1.3)
    --hover-separator-alpha: 1
    --hover-separator-color: hsla(var(--edge-color-hue), var(--edge-color-sat), var(--hover-separator-lum), var(--hover-separator-alpha))
    --hover-background-color: white

    width: 100%
    background-color: var(--background-color)
    padding: 1.5rem
    // margin: 0 0 2rem
    // padding: 1.5rem
    position: relative
    flex-grow: 0
    max-width: none
    border-radius: 4px
    box-shadow: 0 0 1rem hsla(var(--edge-color-hue), var(--edge-color-sat), var(--edge-color-lum), var(--inset-shadow-alpha)) inset
    border: var(--border-width) solid hsla(var(--edge-color-hue), var(--edge-color-sat), var(--edge-color-lum), var(--border-alpha))
    border-left: var(--border-left-width) solid hsla(var(--edge-color-hue), var(--edge-color-sat), var(--edge-color-lum), var(--border-alpha))

    a, a:visited, a:focus, a:active
      color: $primary
    li
      border-left: 0 solid var(--background-color)

    ul
      margin-left: 0
      // margin-top: -1px
      a
        &:hover
          padding-left: 1.5rem
      ul
        a
          margin-left: 2.5rem
          &:hover
            padding-left: 2.5rem
        ul
          a
            margin-left: 3.5rem
            &:hover
              padding-left: 3.5rem
          ul
            a
              margin-left: 4.5rem
              &:hover
                padding-left: 4.5rem
            ul
              a
                margin-left: 5.5rem
                &:hover
                  padding-left: 5.5rem
      // &:last-child li:last-child:not(.cat-parent) a:not(:hover)
      //   border-bottom-color: transparent

    ul, li
      // display: contents

    > ul,
    > div > ul
      // overflow-y: hidden
      // overflow-x: visible
      margin: 0 -1.5rem
      // padding-left: 1.5rem
      // padding-right: 1.5rem
      // &:hover
      //   overflow-y: visible

      // display: grid
      // grid-template-columns: [level1-start] 1.5rem [level2-start] 1.5rem [level3-start] 1.5rem [level4-start] 1fr [level1-end level2-end level3-end level4-end]
      // grid-gap: 1px 0
      // background-color: var(--separator-color)
    > div > ul
      span, div
        margin-left: 1.5rem
        margin-right: 1.5rem
    li
      // margin: -1px 0
      line-height: 1.333
      padding: 0
      // grid-column: content
      // position: relative
      // border-style: solid
      // border-width: 1px 0
      // border-color: transparent
      // margin: -1px 0 0
      position: relative
      z-index: 0
      &:hover
        // border-color: var(--hover-separator-color) transparent
        z-index: 1
        // a
        //   border-color: transparent

      a
        // background-color: var(--background-color)
        display: block
        padding: calc(var(--list-padding-vertical)) 0
        margin: -1px 1.5rem
        border-style: solid
        border-width: 1px 0
        border-color: var(--separator-color) transparent
        // border-style: solid
        // border-color: transparent
        // transition: background-color 1s, border-color 1s, text-decoration-color 1s, z-index 0s 0.5s, margin 0s 0.5s, padding 0s 0.5s
        position: relative
        z-index: 0

        &:hover
          margin-left: 0
          margin-right: 0
          border-color: var(--hover-separator-color) transparent
          background-color: var(--hover-background-color)
          // border-color: var(--hover-separator-color)
          // transition: background-color 0.5s, border-color 0.5s, text-decoration 0.5s, z-index 0s, margin 0s, padding 0s
          z-index: 2
          // padding-top: calc(var(--list-padding-vertical) + 1px)
          // padding-bottom: calc(var(--list-padding-vertical) + 1px)
      + li a
        // border-top-color: var(--separator-color)
      a
        text-decoration: underline
        &:not(:hover)
          text-decoration-color: transparent

      &.current-cat > a
        font-weight: bold
      .post-date
        display: block
        font-size: 0.875em
    .widget-title
      font-size: 1.6rem
    > ul,
    > div > ul
      > li:first-child > a:not(:hover)
        border-top-color: transparent
      > li:last-child
        &,
        > ul:last-child > li:last-child,
        > ul:last-child > li:last-child > ul:last-child > li:last-child,
        > ul:last-child > li:last-child > ul:last-child > li:last-child > ul:last-child > li:last-child
          > a:not(:hover)
            border-bottom-color: transparent

  .rpwwt-widget
    --list-padding-vertical: 1rem
    > ul
      li
        position: relative
        display: flow-root
        border: 0 solid transparent
        border-width: thin 0
        padding: var(--list-padding-vertical) 1.5rem
        transition: background-color 1s, outline-color 1s, padding 0s 0.5s, margin 0s 0.5s
        outline-offset: 0px
        outline: thin solid transparent
        margin-left: -1.5rem
        margin-right: -1.5rem
        margin-bottom: 0
        // &::after
        //   content: ""
        //   position: absolute
        //   left: 0
        //   top: 0
        //   right: 0
        //   bottom: 0
        //   width: 100%
        //   height: 100%
        //   z-index: 0
        //   outline-offset: 1px
        //   outline: thin solid red
        &:hover
          outline-color: var(--hover-separator-color)
          background-color: var(--hover-background-color)
          transition: background-color 0.5s, outline-color 0.5s, padding 1s 0s, margin 1s 0s
          // margin-top: -1px
          // margin-bottom: -1px
          // padding-top: calc(var(--list-padding-vertical) + 1px)
          // padding-bottom: calc(var(--list-padding-vertical) + 1px)

        > a
          margin: calc(var(--list-padding-vertical) * -1) -1.5rem 0.67rem
          padding: calc(var(--list-padding-vertical) - 1px) 1.5rem 0
          border: 0 none
          // display: contents
          font-weight: 600
          // flex-basis: 100%
          color: rgba($primary, 0.66)
          transition-property: color
          transition-duration: 1s
          // position: static
          z-index: 1
          &:hover
            padding-left: 0
            padding-right: 0

          &::after
            content: ""
            position: relative
            width: 100%
            height: 100%
            z-index: 0
            left: 0
            top: 0
            right: 0
            bottom: 0
            background-color: red
        span
          display: block
        div
          &:not(:empty)
            margin-top: 0.333rem
        img
          // grid-row-end: span 4
          margin: 0
          // width: calc(38.2% - 0.5rem + 2rem)
          display: block
          height: auto
          margin: calc(var(--list-padding-vertical) * -1) -1.5rem
          margin-right: 0
          float: left
          z-index: 1
          position: relative
          background-color: transparent
          padding: var(--list-padding-vertical) 1.5rem
          padding-right: 1rem

          width: 100%
          max-width: none
          padding: 0
          margin: 0 0 1rem
        > div
          font-size: 0.875em
          // left: calc(38.2% + 0.5rem)
          // float: none
          &:last-child
            &::after
              clear: both
              content: ""
              display: block
          // min-height: 1rem
        &:hover > a
          color: $primary
          transition-duration: 0.5s

// #secondary .widget li a
//   display: inline
//   border: 0 none
//   margin: 0
