@charset "utf-8"

.single-product
	.product
		padding: $section_padding 0

.woocommerce-breadcrumb,
.woocommerce .woocommerce-breadcrumb,
.woocommerce-breadcrumb + .entry-meta
	margin: 1rem 0
	padding: 0
	font-size: .92em
	color: #777

.woocommerce .products ul,
.woocommerce ul.products
	display: flex
	flex-wrap: wrap
	& li
		display: flex
		flex-direction: column
		& a:first-of-type
			flex-grow: 1
		& h2.woocommerce-loop-product__title
			font-size: 1.2rem !important
			line-height: 1.3
			color: $primary !important
			@include mobile
				font-size: 1.05rem !important

		& .price
			color: #242424 !important
			font-style: italic
		& .button
			margin-top: 0.5rem !important
			align-self: flex-start

.woocommerce main > div.product
	& .entry-summary
		& .entry-title
			letter-spacing: -0.08rem
		& .price
			color: $primary !important
			margin-top: 1rem
		& .qty
			width: 3.631em
			text-align: center
			padding: 0.68rem
			margin-right: 1rem
		& section
			background: transparent

	@supports (display: grid)
		display: grid
		grid-template-columns: 3.82fr 6.18fr
		grid-gap: calc(#{$seed_calc}) #{$seed}vw
		grid-auto-flow: dense

		> *
			grid-column-end: span 2

@include mobile
	.woocommerce #content div.product div.images,
	.woocommerce div.product div.images,
	.woocommerce-page #content div.product div.images,
	.woocommerce-page div.product div.images
		&.bundled_product_images
			float: left
			width: 20%

@media only screen and (min-width: 769px)
	.woocommerce #content div.product div.images,
	.woocommerce div.product div.images,
	.woocommerce-page #content div.product div.images,
	.woocommerce-page div.product div.images
		width: 37%

		@supports (display: grid)
			width: 100%
			grid-column-end: span 1
			grid-row-end: span 2

	.woocommerce #content div.product div.summary,
	.woocommerce div.product div.summary,
	.woocommerce-page #content div.product div.summary,
	.woocommerce-page div.product div.summary,
	.woocommerce #content div.product .woocommerce-tabs,
	.woocommerce div.product .woocommerce-tabs,
	.woocommerce-page #content div.product .woocommerce-tabs,
	.woocommerce-page div.product .woocommerce-tabs
		width: 59%
		margin-left: auto
		clear: right
		float: right

		@supports (display: grid)
			width: 100%
			grid-column-end: span 1

	.woocommerce #content div.product .woocommerce-tabs,
	.woocommerce div.product .woocommerce-tabs,
	.woocommerce-page #content div.product .woocommerce-tabs,
	.woocommerce-page div.product .woocommerce-tabs
		margin-top: calc(#{$seed_calc})

		@supports (display: grid)
			grid-column-start: 2
			margin-top: 0

		h3
			clear: none

.woocommerce div.product form.cart:after, .woocommerce div.product form.cart:before
	// display: none

.wc-tabs
	justify-content: flex-start
	// display: none

// .woocommerce-cart-form__cart-item

.coupon
	& input
		padding: 0.69rem !important
		width: 10rem !important

.woocommerce-cart .wc-proceed-to-checkout
	padding: 0

.woocommerce-products-loop
	ul.products
		margin-left: -10px
		margin-right: -10px

		li.product
			flex-grow: 1
			padding-left: 10px
			padding-right: 10px

//  bundled products
div.bundled_product_summary,
.woocommerce div.product.bundled_product_summary
	padding-top: 0
	padding-bottom: 3.236em
	margin: 3.236em 0

// Sale

.woocommerce span.onsale
	display: none

	min-height: 50px
	min-width: 50px
	padding: 0
	font-size: 1em
	font-weight: 700
	position: absolute
	text-align: center
	line-height: 50px
	top: -.5em
	left: -.5em
	margin: 0
	border-radius: 50%
	background-color: #77a464
	color: #fff
	font-size: .857em
	z-index: 9

.woocommerce.archive
	#main
		display: block
