@charset "utf-8"

.container
  width: 100%
  margin: 0 auto
  &.is-narrow
    max-width: 720px !important

  & > p
    &:last-child
      margin-bottom: 0

// .container,
// .content-area 
//   @media screen and (min-width: 1024px)
//     max-width: 984px // 960px
//     padding-left: 12px
//     padding-right: 12px
//   @media screen and (min-width: 1216px)
//     max-width: 1176px // 1152px
//   @media screen and (min-width: 1240px)
//     max-width: 1200px // 1176px




