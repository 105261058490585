/* -- Color Defaults -- */

// Colors
$black:	hsl(0, 0%, 4%)
$black-bis:	hsl(0, 0%, 7%)
$black-ter:	hsl(0, 0%, 14%)
$grey-darker:	hsl(0, 0%, 21%)
$grey-dark:	hsl(0, 0%, 29%)
$grey: hsl(0, 0%, 48%)
$grey-light:	hsl(0, 0%, 71%)
$grey-lighter:	hsl(0, 0%, 86%)
$white-ter:	hsl(0, 0%, 96%)
$white-bis:	hsl(0, 0%, 98%)
$white:	hsl(0, 0%, 100%)
$orange: hsl(14, 100%, 53%)
$yellow: hsl(48, 100%, 67%)
$green:	hsl(141, 71%, 48%)
$turquoise:	hsl(171, 100%, 41%)
$cyan:	hsl(204, 86%, 53%)
$blue:	hsl(217, 71%, 53%)
$purple: hsl(271, 100%, 71%)
$red: hsl(348, 100%, 61%)

// Color Elements
$white: hsl(0, 0%, 100%)
$black: hsl(0, 0%, 4%)
$light: $white-ter
$dark: $grey-darker
$primary: #251D72
// $secondary: #665eb7
$secondary: desaturate(lighten($primary, 38%), 22%)
// $secondary-light: #f8f7fd
$secondary-light: lighten($primary, 69%)
$link: $blue
$info: $cyan
$success: $green
$warning: $orange
$danger: $red
