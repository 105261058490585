.site-nav-secondary

  background-color: $white

  .inner-anchor
    svg
      margin-right: 0.66em

  .menu-cart
    .inner-anchor--text
      .cart-contents
        display: none

    .cart-contents
      font-weight: bold

  // TODO: use light version of icons
  @include mobile
    a
      background-color: transparentize( $primary, 0.96 )

    .inner-anchor
      justify-content: flex-start

      svg
        order: -1
        width: 1em

    .menu-cart
      .count
        margin-left: 0.5em

  @include tablet
    padding-top: 15px
    padding-bottom: 13px
    height: 90px
    // flex-grow: 1
    flex-shrink: 1
    // width: 100%
    // height: 100px
    // padding: 0 $viewport_margin
    .inner-anchor svg
      margin-right: 0.5em
    a, .inner-anchor
      svg
        order: -1
      > * + *
        margin-left: 0
        margin-right: 0.33em
    .menu-item
      & + *
        margin-left: 1.5rem
      a
        padding: 10px
        margin: 0 -10px
        height: 42px
        display: inline-flex
        align-items: center
        border: 0 none
        // border-radius: 4px
        line-height: 1
        font-weight: 300
        font-size: 0.9rem

        transition: 300ms
        display: inline-flex
        align-items: center
      svg
        max-height: 0.8rem
        vertical-align: middle
      & > a:hover,
      & > a:focus
        text-decoration: underline
        background-color: transparent
        outline: 0 none
    .menu-cart
      .inner-anchor--text
        display: none

  @include tablet-only
    .menu-item
      a
        font-size: 0.85rem
      svg
        max-height: 0.75rem

  @media (min-width: 769px) and (max-width: 820px)
    .menu-item
      a
        font-size: 0.8rem
      svg
        max-height: 0.65rem
