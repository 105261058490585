@charset "utf-8"

$seed: 3
$seed_calc: #{$seed}vh + #{$seed}vw
$section_padding: calc(#{$seed_calc})
$section_padding_double: calc((#{$seed_calc}) * 2)
$section_feather_height: $section_padding_double
$section_feather_overlap: calc((#{$seed_calc}) * -1)
$section_overlap: calc((#{$seed_calc}) * -2)
// $viewport_margin: 6.25vw
$viewport_margin_calc: "10px + 3.125vw"
$viewport_margin: calc(#{$viewport_margin_calc})

%viewport_margin
  padding-left: $viewport_margin
  padding-right: $viewport_margin

html
  background: none
  scroll-behavior: smooth

html,
body,
.site
  min-height: 100% !important

body
  margin: 0 !important
  // overflow-x: hidden

.site
  display: flex
  flex-direction: column
  background: white

.site-content
  flex: 1

  @extend %viewport_margin

#header
  flex-shrink: 0

%container_breakout
  width: 100vw
  position: relative
  left: 50%
  right: 50%
  margin-left: -50vw
  margin-right: -50vw

  @extend %viewport_margin

.site-content
  padding-top: 90px
  @include x( $m )
    padding-top: 100px
  @supports (position: sticky) or (position: -webkit-sticky)
    padding-top: 0

.content-area

  @extend .container

.site-main
  > header + .container
    z-index: 1

  .page &
    & > section,
    & > header,
    & > footer
      @extend %container_breakout

    & > section,
    & > footer
      padding-top: calc((#{$seed_calc}) * 3)
      padding-bottom: calc((#{$seed_calc}) * 2)
      &:not(.with-background)
        padding-top: calc((#{$seed_calc}) * 3)
        padding-bottom: calc((#{$seed_calc}) * 3)
    .page-context-nav
      top: 80px
      position: relative
      + section:not(.with-background)
        padding-top: calc(((#{$seed_calc}) * 3) + 100px)

    & > * + section,
    & > * + footer
      // mask-image: linear-gradient(to bottom, black, black 100%)
      margin-top: calc((#{$seed_calc}) * -2)
      mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) calc((#{$seed_calc}) * 2))

    & > :last-child
      &.with-background
        margin-bottom: calc(#{$seed_calc})
        mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) calc((#{$seed_calc}) * 2), rgba(0, 0, 0, 1) calc(100% - ((#{$seed_calc}) * 2)), rgba(0, 0, 0, 0) 100%)
        // .background-image

      &:not(.with-background)
        padding-bottom: calc(#{$seed_calc})
        border-bottom: thin solid #eeeeee
        margin-bottom: calc(#{$seed_calc})

body:not(.page)
  .site-content > .container
    padding-bottom: calc(#{$seed_calc})
    border-bottom: thin solid #eeeeee
    margin-bottom: calc(#{$seed_calc})

.site
  & > section
    @extend %viewport_margin

    @include mobile
      padding-bottom: calc((#{$seed_calc}) * 2)

  & > footer > *
    @extend %viewport_margin
