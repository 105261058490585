@charset "utf-8"

.widget
  max-width: 360px
  margin: 0 auto

.widget-title,
.widget_calendar table > caption
  margin-bottom: 1em

.widget_calendar
  #wp-calendar
    max-width: 300px
    border: 1px solid $grey-lighter
    border-collapse: collapse
  table > caption
    text-align: left
    font-weight: 500
  td,
  th
    text-align: center
    line-height: 2.5em
    padding: 4px
    border: 1px solid $grey-lighter
    &:hover
      background: $grey-lighter