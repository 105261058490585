#newsletter
  background: transparent

  @include from($tablet)
    padding-bottom: $section_padding

  @include mobile
    flex-grow: 1
    margin-left: 0
    margin-right: 0

  .content-block
    @extend %viewport_margin

  & .columns
    display: flex
    @include mobile
      flex-direction: column-reverse
    @include from($tablet)
      align-items: flex-end

    & .column
      padding: 0.75rem

      @include from($tablet)
        &:first-child
          margin-right: 20px

        & > img
          display: block
          // height: 100%
          width: 100%
          object-fit: contain
          margin-bottom: $section_feather_overlap

  #mc_subheader
      line-height: 1.5em

  .mc_signup_submit
    text-align: left

  #mc-indicates-required
    margin-bottom: 1em

  .mc_form_inside
    display: flex
    flex-wrap: wrap
    margin: 0 -10px

    & > *
      margin-left: 10px
      margin-right: 10px
      flex: 1 1 100%

    @include tablet
      & > :nth-child(2)
        margin-right: calc(100% - 48%)
      & > :nth-child(3),
      & > :nth-child(4)
        flex-basis: 40%

    & > :nth-child(5),
    & > :nth-child(6)
      flex-basis: 100%
